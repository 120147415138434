.profile {
  width: 100vw;
  min-height: 100vh;
  margin-top: 60px;
  margin-bottom: 40px;
  padding-top: 60px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
}

@media (min-width: 2080px) {
  .profile {
    padding-left: 120px;
  }
}

@media (max-width: 1024px) {
  .profile {
    padding-top: 50px;
    padding-right: 80px;
    padding-left: 160px;
    height: auto;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

@media (max-width: 696px) {
  .profile {
    margin-top: 0;
    padding-top: 90px;
    padding-left: 25px;
    padding-right: 25px;
    overflow-x: hidden;
  }
}

@media (max-width: 696px), (max-height: 696px) {
  .profile {
    padding-right: var(--spaceOuter);
    padding-left: var(--spaceOuter);
  }
}

@media (max-width: 820px) and (max-height: 420px) {
  .profile {
    padding-right: var(--space4XL);
    padding-left: var(--space4XL);
  }
}

.profile__content {
  display: grid;
  grid-template-columns: 1fr 50%;
  grid-column-gap: var(--space2XL);
  max-width: var(--maxWidthL);
  width: 100%;
}

@media (max-width: 1024px) {
  .profile__content {
    max-width: 600px;
    grid-template-columns: 100%;
  }
}

.profile__column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  transform: translate3d(0, 0, 0);
}

.profile__title {
  white-space: nowrap;
  margin-bottom: var(--spaceL);
  opacity: 0;
  transition: opacity var(--durationXL) ease var(--durationM);
}

.profile__title--entered {
  opacity: 1;
}

.profile__description {
  margin-bottom: var(--spaceXL);
  opacity: 0;
  transition: opacity var(--durationXL) ease var(--durationL);
}

.profile__description--entered {
  opacity: 1;
}

.profile__tag {
  margin-top: 220px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: var(--space4XL) 1fr;
  gap: 12px;
  align-items: center;
}

@media (max-width: 1024px) {
  .profile__tag {
    margin-top: 30px;
  }
}

.profile__tag-text {
  font-size: var(--fontSizeBodyS);
  font-weight: var(--fontWeightMedium);
  color: rgb(var(--rgbPrimary));
  transform: translateX(calc(var(--spaceM) * -1));
  opacity: 0;
  transition-property: opacity, transform;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationM);
  transition-delay: 1.3s;
}

.profile__tag-text--entered {
  transform: translateX(0);
  opacity: 1;
}

@keyframes image-reveal {
  0% {
    transform: scale3d(0, 1, 1);
    transform-origin: left;
  }
  49% {
    transform: scale3d(1, 1, 1);
    transform-origin: left;
  }
  50% {
    transform: scale3d(1, 1, 1);
    transform-origin: right;
  }
  100% {
    transform: scale3d(0, 1, 1);
    transform-origin: right;
  }
}

.profile__image-wrapper {
  position: relative;
  max-width: 100%;
}

.profile__image {
  max-width: 100%;
  width: 960px;
  height: 560px;
  position: relative;
}

@media (max-width: 1024px) {
  .profile__image {
    width: 960px;
    height: 1120px;
  }
}

@media (max-width: 696px) {
  .profile__image {
    width: 480px;
    height: 560px;
  }
}

.profile__image::before {
  content: '';
  background-color: rgb(var(--rgbAccent));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 1, 1);
  transform-origin: left;
  z-index: 16;
}

.profile__image--entered::before {
  animation: image-reveal 1.8s var(--bezierFastoutSlowin) var(--delay);
  animation-iteration-count: 1;
}

.profile__image .selfie__canvas {
  opacity: 0;
}

.profile__image--entered .selfie__canvas {
  animation: fade-in var(--durationM) ease var(--delay) forwards;
  animation-iteration-count: 1;
  animation-delay: 1s;
}

.profile__button {
  opacity: 0;
  transition: opacity var(--durationXL) ease var(--durationL);
}

.profile__button--entered {
  opacity: 1;
}
