.project-summary {
  height: 100vh;
  width: 100vw;
  max-height: 1080px;
  padding-bottom: var(--spaceL);
  margin: 120px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.project-summary--first {
  margin-top: 0;
}

@media (min-width: 2080px) {
  .project-summary {
    margin: 40px 0;
  }
}

@media (max-width: 1024px) {
  .project-summary {
    height: auto;
    margin: 60px 0;
  }

  .project-summary--first {
    margin-top: 0;
  }
}

@media (max-width: 696px) {
  .project-summary {
    padding-bottom: var(--space4XL);
    margin-bottom: 0;
    overflow-x: hidden;
  }
}

.project-summary__content {
  width: 100%;
  max-width: var(--maxWidthL);
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-column-gap: var(--space2XL);
}

.project-summary--alternate .project-summary__content {
  grid-template-columns: 1fr 40%;
}

@media (max-width: 1280px) {
  .project-summary__content {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1024px) {
  .project-summary--alternate .project-summary__content {
    grid-template-columns: 100%;
  }
}

@media (max-width: 1024px) {
  .project-summary__content {
    grid-template-columns: 100%;
    flex-direction: column-reverse;
    height: auto;
  }
}

.project-summary__details {
  z-index: 1;
  position: relative;
}

@media (max-width: 1024px) {
  .project-summary__details {
    flex: 0 0 auto;
    max-width: 410px;
    grid-row: 2;
    grid-column: 1;
    justify-self: center;
  }
}

.project-summary__preview {
  flex: 0 1 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.project-summary__model {
  --width: 180%;
  --aspectRatio: 10 / 12;

  min-width: var(--width);
  padding-bottom: calc(var(--aspectRatio) * var(--width));
}

.project-summary__model--laptop {
  top: 6%;
  left: 14%;
}

@media (max-width: 2080px) {
  .project-summary__model--laptop {
    --width: 180%;
  }
}

@media (max-width: 1024px) {
  .project-summary__model--laptop {
    --width: 140%;

    top: 10%;
    left: 0;
  }
}

.project-summary__model--phone {
  --width: 116%;
  --aspectRatio: 10 / 7;

  top: 5%;
  left: -10%;
}

@media (max-width: 2080px) {
  .project-summary__model--phone {
    --width: 108%;

    left: 0;
  }
}

@media (max-width: 1024px) {
  .project-summary__model--phone {
    --width: 108%;

    left: 0;
  }
}

.project-summary__index {
  position: relative;
  display: grid;
  grid-template-columns: 90px 1fr;
  gap: var(--spaceM);
  align-items: center;
  margin-bottom: var(--spaceXL);
}

.project-summary__index-number {
  font-size: var(--fontSizeBodyS);
  font-weight: var(--fontWeightMedium);
  color: rgb(var(--rgbPrimary));
  transform: translateX(calc(var(--spaceM) * -1));
  opacity: 0;
  transition-property: transform, opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationM);
  transition-delay: 1.3s;
}

.project-summary__index-number--entered {
  transform: translateX(0);
  opacity: 1;
}

.project-summary__title {
  margin-bottom: var(--spaceL);
  transition-property: transform, opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationM);
  transform: translate3d(0, var(--spaceL), 0);
  opacity: 0;
}

.project-summary__title--entered {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.project-summary__description {
  margin-bottom: var(--spaceXL);
  transition-property: transform, opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationL);
  transform: translate3d(0, var(--spaceL), 0);
  opacity: 0;
}

.project-summary__description--entered {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.project-summary__button {
  transition-property: transform, opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationXL);
  transform: translate3d(0, var(--spaceL), 0);
  opacity: 0;
}

.project-summary__button--entered {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
